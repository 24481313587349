<template>
  <div>
    <v-row align="end" justify="space-between" no-gutters>
      <v-col cols="auto">
        <GoBack />
        <p class="text-h4 font-weight-medium">
          {{ studentData.user.first_name }} {{ studentData.user.last_name }}
        </p>
        <p class="text--secondary font-weight-medium caption">
          {{ studentData.user.email }} · {{ studentData.batch.name }}
        </p>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-4" />
    <v-row class="mb-10 mt-4" dense>
      <v-col v-for="(item, index) in cards" :key="index" cols="12" md="6">
        <AnalyticsCard
          :body="item.body"
          :color="item.color"
          :title="item.title"
        />
      </v-col>
    </v-row>
    <div class="mb-6">
      <StudentClassesSearch
        :course="course"
        :get-student-data="getStudentData"
        :list-loading="listLoading"
        :query="query"
        :status="status"
        @input-change="handleInputChange"
      />
    </div>
    <v-data-table
      :custom-sort="() => classesDataList.data"
      :headers="headers"
      hide-default-footer
      item-key="uuid"
      :items="classesDataList.data"
      :loading="listLoading"
      multi-sort
      :sort-by="sort.fields"
      :sort-desc="sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-col v-if="item.status === 'present'" class="green--text">
          Present
        </v-col>
        <v-col v-if="item.status === 'absent'" class="red--text">
          Absent
        </v-col>
        <v-col v-if="item.status === null" class="orange--text">
          Not Marked
        </v-col>
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ moment(item.start_time).format("LLL") }}
        -
        {{ moment(item.end_time).format("LLL") }}
      </template>
      <template v-slot:footer>
        <Paginator
          :page="page"
          :total="classesDataList.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import urls from "@/utils/urls"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"
import StudentClassesSearch from "@/components/dashboard/classes/StudentClassesSearch.vue"
import AnalyticsCard from "@/components/shared/AnalyticsCard.vue"
import Paginator from "@/components/shared/Paginator"
import GoBack from "@/components/shared/GoBack.vue"

export default {
  components: { StudentClassesSearch, Paginator, GoBack, AnalyticsCard },
  data: () => ({
    page: 1,
    sort: { fields: [], desc: [] },
    batchId: null,
    studentId: null,
    listLoading: false,
    query: "",
    course: "",
    status: "",
    studentData: {
      class_attended: 0,
      total_classes_conducted: 0,
      batch: {
        name: "Batch",
      },
      user: {
        first_name: "Student",
        last_name: "",
        email: "email",
      },
    },
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
      },
      { text: "Course", value: "course", sortable: false },
      { text: "Time", value: "time" },
      { text: "Status", value: "status" },
      { text: "", value: "action", sortable: false, align: "end" },
    ],
    classesDataList: {
      data: [],
      total: 0,
    },
  }),
  computed: {
    totalEntries() {
      return `${
        this.page * 10 > this.classesDataList.total
          ? this.classesDataList.total
          : this.page * 10
      } of ${this.classesDataList.total} Entries`
    },

    cards() {
      const { total_classes_conducted, class_attended } = this.studentData
      return [
        {
          title: "Total Lectures",
          color: "purple",
          body: total_classes_conducted,
        },
        {
          title: "Classes Attended",
          color: "green",
          body: class_attended,
        },
      ]
    },
  },

  beforeMount: function() {
    this.batchId = this.$route.params.batchId
    this.studentId = this.$route.params.studentId
  },

  mounted: function() {
    this.getStudentData()
  },
  methods: {
    //handle Sort
    handleSort({ sortBy, sortDesc }) {
      this.resetPage()
      this.sort = {
        fields: sortBy,
        desc: sortDesc,
      }
      this.getclassesDataList()
    },

    handleInputChange(key, value) {
      this[key] = value
      key !== "page" && this.resetPage()
      this.getclassesDataList()
      this.getStudentData()
    },

    resetPage() {
      this.page = 1
    },
    async getStudentData() {
      try {
        const res = await this.$http.get(
          urls.classes.getStudentClassOverview(this.batchId, this.studentId),
          {
            params: {
              course: getValueOrNull(this.course),
              status: getValueOrNull(this.status),
            },
          }
        )
        this.studentData = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getclassesDataList() {
      this.listLoading = true
      try {
        const res = await this.$http.get(
          urls.classes.getStudentClassList(this.batchId, this.studentId),
          {
            params: {
              query: getValueOrNull(this.query),
              course: getValueOrNull(this.course),
              status: getValueOrNull(this.status),
              sort: getSortableFieldsArray(this.sort.fields, this.sort.desc),
              page: this.page,
            },
          }
        )
        this.classesDataList = res.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.listLoading = false
      }
    },
    handlePagination(newPage) {
      this.page = newPage
      this.getclassesDataList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
